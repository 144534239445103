<template>
  <nut-navbar @on-click-back="back" title="店铺详情" @on-click-right="rightClick">
    <template #left>
      <div>返回</div>
    </template>
    <template #right>
      <Scan color="#375DE9;"></Scan>
    </template>
  </nut-navbar>
  <div>
    <div style="padding:3px;background-color: #eeeeee;">
      <nut-form>
        <nut-form-item label="店铺名称:">
          <nut-input v-model="shop.name" placeholder="请输入店铺名称" :border="false"/>
        </nut-form-item>
        <nut-form-item label="店铺图片:">
          <nut-image
            :src="shop.shopImage"
            width="100"
            height="100"
          ></nut-image>
        </nut-form-item>
        <nut-form-item label="店铺地址:">
          <nut-input v-model="shop.address" placeholder="请输入店铺地址" :border="false"/>
        </nut-form-item>
        <nut-form-item label="店铺维度:">
          <nut-input v-model="shop.latitude" placeholder="请输入店铺维度" :border="false"/>
        </nut-form-item>
        <nut-form-item label="店铺经度:">
          <nut-input v-model="shop.longitude" placeholder="请输入店铺经度" :border="false"/>
        </nut-form-item>
        <nut-form-item label="店铺类型:">
          <nut-menu>
            <nut-menu-item v-model="shop.shopType" :options="shoptypelist" />
          </nut-menu>
        </nut-form-item>
        <nut-form-item label="店铺状态:">
          <nut-menu>
            <nut-menu-item v-model="shop.status" :options="shopstatuslist" />
          </nut-menu>
        </nut-form-item>
        <nut-form-item label="工位数量:">
          <nut-input v-model="shop.stationNum" placeholder="请输入工位数量" :border="false"/>
        </nut-form-item>
        <nut-form-item label="起步价:">
          <nut-input v-model="shop.startPrice" placeholder="请输入起步价" :border="false"/>
        </nut-form-item>
        <nut-form-item label="起步价时间:">
          <nut-input v-model="shop.startTime" placeholder="请输入起步价时间" :border="false"/>
        </nut-form-item>
        <nut-form-item label="超起步价收费:">
          <nut-input v-model="shop.ratePrice" placeholder="请输入超出每分钟收费" :border="false"/>
        </nut-form-item>
        <nut-form-item label="年租金:">
          <nut-input v-model="shop.rent" placeholder="请输入年租金" :border="false"/>
        </nut-form-item>
        <nut-form-item label="装修和设备费:">
          <nut-input v-model="shop.decoration" placeholder="请输入装修和设备费" :border="false"/>
        </nut-form-item>
        <nut-button type="info" @click="shopupdate()" style="margin-top: 20px;">确认更新</nut-button>
      </nut-form>
    </div>
  </div>

  <nut-overlay v-model:visible="show" style="display: flex;align-items: center;justify-content:center;">
    <Loading color="white" width="2rem" height="2rem"></Loading>
  </nut-overlay>
</template>

<script>
import {getShop,updateShop} from '@/api/shop'
import { onMounted, reactive, ref, toRefs } from 'vue'
import { Loading,Scan } from '@nutui/icons-vue';
import '@nutui/icons-vue/dist/style_iconfont.css';
import { useRouter } from 'vue-router'
import {getDicts} from '@/api/dict/data'
import { showToast } from '@nutui/nutui';

export default{
  name: "ShopDetail",

  components:{
    Loading,Scan
  },
  setup(props) {
    const router=useRouter();
    const state = reactive({
      show: false,
      shoptypelist:[],
      shopstatuslist:[]
    });
    const shop=ref({
      id: null,
      name:"",
      longitude:"",
      address: "",
      latitude: "",
      shopType: null,
      shopImage: "",
      status: null,
      startPrice:null,
      startTime:null,
      ratePrice:null,
      stationNum:null,
      rent:null,
      decoration:null

    })
    const back = () => {
      router.back()
    };
    const rightClick=()=>{
      router.push("/feature/content")
    }
    //字典处理方法
    function dictmethod(){
      getDicts("shop_type").then(res=>{
        for (let i = 0; i < res.data.length; i++){
          let dict={}
          dict.text=res.data[i].dictLabel
          dict.value=Number(res.data[i].dictValue)
          state.shoptypelist.push(dict)
        }
      })
      getDicts("shop_status").then(res=>{
        for (let i = 0; i < res.data.length; i++){
          let dict={}
          dict.text=res.data[i].dictLabel
          dict.value=Number(res.data[i].dictValue)
          state.shopstatuslist.push(dict)
        }
      })
    }
    //查询店铺
    function getshopByid(id){
      state.show=true
      getShop(id).then(res=>{
        shop.value=res.data
        console.log(shop.value)
        state.show=false
        dictmethod()
      })
    }
    function shopupdate(){
      state.show=true
      updateShop(shop.value).then(res=>{
        state.show=false
        if (res.code==200){
          showToast.success("更新成功！")
        }
      })
    }
    //挂载后调用函数获取项目数据
    onMounted(() => {
      let id=router.currentRoute.value.query.id
      getshopByid(id)
    });
    return {
      ...toRefs(state),
      back,rightClick,shop,shopupdate
    };
  },
  created () {

  }
}
</script>
<style lang="scss">

</style>
