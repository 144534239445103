<template>
  <nut-navbar @on-click-back="back" title="提现" @on-click-right="rightClick">
    <template #left>
      <div>返回</div>
    </template>
    <template #right>
      <Scan color="#375DE9;"></Scan>
    </template>
  </nut-navbar>
  <div style="width: 100%;display: flex;flex-direction: column;align-items: center;">
    <nut-swiper :pagination-visible="true" pagination-color="#426543" auto-play="3000">
      <nut-swiper-item>
        <img src="https://www.lightwashcar.top/headimg/npo.jpg" alt="" />
      </nut-swiper-item>
    </nut-swiper>
    <div style="width: 90%;">
      <nut-form>
        <nut-form-item label="提现金额" label-align="center" type="text" :border="data.borderflag" required>
          <input class="nut-input-text" v-model="paramData.price" style="font-weight: bold;color: red;"/>
        </nut-form-item>

        <nut-form-item label="提现方式" required>
          <nut-radio-group v-model="paramData.tixianType">
            <nut-radio shape="button" :label="0">
              <IconFont font-class-name="iconfont" class-prefix="icon" name="yinxingqia3" color="#498ff2" size="25px"></IconFont>
              银行卡提现
            </nut-radio>
            <nut-radio shape="button" :label="1">
              <IconFont font-class-name="iconfont" class-prefix="icon" name="weixin2" color="#498ff2" size="25px"></IconFont>
              微信提现
            </nut-radio>
          </nut-radio-group>
        </nut-form-item>

      </nut-form>
      <div style="font-size: 14px;color: red;">微信提现及时到账，银行卡提现每周六到账!</div>
      <nut-button type="info" :loading="data.isLoading" @click="confirmwithdraw()" style="width:100%;margin-top:15px;">确认提现</nut-button>
    </div>
  </div>
</template>
<script>
import {ref, reactive, onMounted} from 'vue';
import { IconFont,Scan } from '@nutui/icons-vue';
import '@nutui/icons-vue/dist/style_iconfont.css';
import {getUserProfile} from '@/api/user';
import {tixian} from '@/api/withdraw'
import { showToast } from '@nutui/nutui';
import { useRouter } from 'vue-router'

export default {
  components: {
    IconFont,
    Scan
  },
  setup() {
    const router=useRouter();
    const data = reactive({
      selectActiveId: null,
      borderflag: false
    });
    const paramData = reactive({
      tixianType: 0,
      price: 0
    });
    const confirmwithdraw=()=>{

      tixian(paramData).then((response)=>{
        if (response.code==200){
          showToast.success(response.msg)
        }
        else{
          showToast.warn(response.msg)
        }

      })
    }
    const selectprice=(id)=>{
      paramData.multiId=id
    }
    function  init(){
    }
    const back = () => {
      router.back()
    };
    const rightClick=()=>{
      router.push("/my/content")
    }
    //挂载获取地址中参数
    onMounted(()=>{
      //获取个人用户余额
      getUserProfile().then((response)=>{
          if (response.code==200){
            paramData.price=response.data.remain
          }
      })


    })
    return{
      data,
      paramData,
      confirmwithdraw,back,rightClick,
    }
  }
}
</script>

<style lang="scss">
.paytype{
  height: 80px;
  margin-top:40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.paypage{
  width: 90%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.nut-row {
  overflow: hidden;
  &:not(:last-child) .nut-col {
    margin-bottom: 15px;
  }
  margin-bottom: 15px;
}
.flex-content {
  line-height: 40px;
  font-weight: bold;
  text-align: center;
  border-radius: 8px;
  border: 2px solid #2076ff;;
  height: 140px;
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}

.selected {
  background-color: #2076ff;
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.pricecard{
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 15px 0 rgba(0, 0, 0, 0.19);
}

.rechangedisplaynone{
  display: none;
}
.rechangedisplay{
  display: block;
}
.countdown-part-box {
  display: flex;
  align-items: center;
}
.part-item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 35px;
  background: #e8220e;
  color: #fff;
  font-size: 25px;
  border-radius: 6px;
}

.part-item-symbol {
  margin: 0 5px;
}
.tips{
  display: flex;
  flex-direction: row;
  justify-content:center;
  margin-top:30px;
  font-size: 25px;
}
</style>
