<template>
</template>
<script>
import { wechatAuthorize } from "@/api/wxlogin";
import {  setToken } from '@/utils/auth'
export default {

  name: "Weixinauth",
  data () {
    return {
    }
  },
  created () {
    // alert("进来回调")
    this.getPersonInfo();

  },
  methods:{
    // 这个是为了获取地址里面的参数
    getUrlParam: function(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");　　　　　//构造一个含有目标参数的正则表达式对象
      let arr = window.location.search.substr(1).match(reg);　　　　　　　 //匹配目标参数
      if (arr) {
        return decodeURIComponent(arr[2]);
      }
      return null;
    },
    getPersonInfo: function() {
      let formData = new FormData();
      formData.append("code", this.getUrlParam('code'));
      formData.append("state", "");
      let userId=this.getUrlParam('userId')
      let deptId=this.getUrlParam('deptId')
      let batchId=this.getUrlParam('batchId')
      let path=this.getUrlParam('state');
      var params={}
      if (deptId!==null && deptId!== undefined){
        if (userId!==null && userId!== undefined){
          params.userId=userId
          params.deptId=deptId
        }
        else{
          params.deptId=deptId
        }
      }
      if (batchId!==null && batchId!== undefined){
        params.batchId=batchId
      }
      // 发送code到后台获取openid并登录系统
      // // 调用后台方法：code换openid， openid换人员信息
      wechatAuthorize(formData).then((res) => {
        var resjson=JSON.stringify(res)
        // alert(resjson)
        setToken(res.token)
        // this.$router.push(path)
        this.$router.push({ path: path, query: params })
        // this.$store.dispatch("SetToken", res.token).then(() => {
        //   // alert("缓存token成功")
        //
        // })
      })
    }
  }
}
</script>
