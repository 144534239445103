import { createRouter ,createWebHistory} from 'vue-router'
import Weixinauth from '@/views/wechat/weixinauth'
import my from '@/views/my/index'
import Index from '@/views/index'
import Feature from '@/views/feature/index'
import LightTabbar from '../components/LightTabbar'
import Withdraw from '@/views/withdraw/index'
import Shops from '@/views/shops/index'
import ShopDetail from '@/views/shops/detail'
import Cameras from '@/views/cameras/index'
import CameraDetail from '@/views/cameras/detail'
import Remains from '@/views/remain/index'
import Topups from '@/views/topups/index'
import Orders from '@/views/orders/index'
import Person from '@/views/personal/index'
import WithdrawList from '@/views/withdraw/list'

const routes = [
  {
    path: '/index',
    name: 'Index',
    component: LightTabbar,
    children: [
      {
        path: 'content',
        component: Index
      }
    ]
  },
  {
    path: '/my',
    name: 'My',
    component: LightTabbar,
    children: [
      {
        path: 'content',
        component: my
      }
    ]
  },
  {
    path: '/feature',
    name: 'feature',
    component: LightTabbar,
    children: [
      {
        path: 'content',
        component: Feature
      }
    ]
  },
  {
    path: '/wechat/weixinauth',
    name: 'weixinauth',
    component: Weixinauth
  },

  {
    path: '/withdraw',
    name: 'withdraw',
    component: Withdraw
  },
  {
    path: '/withdrawlist',
    name: 'withdrawlist',
    component: WithdrawList
  },
  {
    path: '/shops',
    name: 'shops',
    component: Shops
  },
  {
    path: '/shopdetail',
    name: 'shopdetail',
    component: ShopDetail
  },
  {
    path: '/cameras',
    name: 'cameras',
    component: Cameras
  },
  {
    path: '/cameradetail',
    name: 'cameradetail',
    component: CameraDetail,
  },
  {
    path: '/remains',
    name: 'remains',
    component: Remains
  },
  {
    path: '/topups',
    name: 'topups',
    component: Topups
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders
  },
  {
    path: '/personal',
    name: 'personal',
    component: Person
  },
  {
    path: '/login',
    component: () => import('@/views/login')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
