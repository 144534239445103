<template>
  <nut-navbar @on-click-back="back" title="工位详情" @on-click-right="rightClick">
    <template #left>
      <div>返回</div>
    </template>
    <template #right>
      <Scan color="#375DE9;"></Scan>
    </template>
  </nut-navbar>
  <div>
    <nut-row>
      <nut-col :span="12">
        <div style="display: flex;flex-direction: column;align-items: center;height: 100%;" @click="opendoor">
          <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="kaimen-05" color="#0061b0" size="25px"></IconFont>
          开门
        </div>
      </nut-col>
      <nut-col :span="12">
        <div style="display: flex;flex-direction: column;align-items: center;height: 100%;">
          <nut-switch v-model="openstatus" @click="openLed"/>
          <div v-if="openstatus">已通电</div>
          <div v-if="!openstatus">已关电</div>
        </div>
      </nut-col>
    </nut-row>
    <div style="padding:3px;background-color: #eeeeee;">
      <nut-form>
        <nut-form-item label="店铺名称:">
          <nut-input v-model="camera.shop.name" placeholder="请输入店铺名称" :border="false" readonly/>
        </nut-form-item>
        <nut-form-item label="工位名称:">
          <nut-input v-model="camera.deviceName" placeholder="请输入工位名称" :border="false" readonly/>
        </nut-form-item>
        <nut-form-item label="序列号:">
          <nut-input v-model="camera.serialno" placeholder="请输入序列号" :border="false" readonly/>
        </nut-form-item>
        <nut-form-item label="工位状态:">
          <nut-menu>
            <nut-menu-item v-model="camera.status" :options="cameratatuslist" readonly />
          </nut-menu>
        </nut-form-item>
<!--        <nut-form-item label="到期时间:">-->
<!--          <nut-input v-model="camera.rent_time" placeholder="请输入到期时间" :border="false" readonly/>-->
<!--        </nut-form-item>-->
        <div style="color: red;">修改状态会同步修改车牌识别器的显示。</div>
        <nut-button type="info" @click="cameraupdate()" style="margin-top: 20px;">确认更新</nut-button>
      </nut-form>

    </div>
  </div>

  <nut-overlay v-model:visible="show" style="display: flex;align-items: center;justify-content:center;">
    <Loading color="white" width="2rem" height="2rem"></Loading>
  </nut-overlay>
</template>

<script>
import {getCamera,updateCamera,cameraOpen,cameraOpenLed,cameraCloseLed} from '@/api/camera'
import { onMounted, reactive, ref, toRefs } from 'vue'
import { IconFont, Loading, Scan } from '@nutui/icons-vue'
import '@nutui/icons-vue/dist/style_iconfont.css';
import { useRouter } from 'vue-router'
import {getDicts} from '@/api/dict/data'
import { showToast } from '@nutui/nutui';

export default{
  name: "CameraDetail",
  components:{
    Loading,Scan,IconFont
  },
  setup() {
    const router=useRouter();
    const state = reactive({
      show: false,
      cameratatuslist:[],
      openstatus: null,
    });
    const camera=ref({
      id: null,
      name:"",
      status:null,
      deviceName:null,
      shop:{}
    })
    const back = () => {
      router.back()
    };
    const rightClick=()=>{
      router.push("/feature/content")
    }
    //字典处理方法
    function dictmethod(){

      getDicts("station_status").then(res=>{
        for (let i = 0; i < res.data.length; i++){
          let dict={}
          dict.text=res.data[i].dictLabel
          dict.value=Number(res.data[i].dictValue)
          state.cameratatuslist.push(dict)
        }
      })
    }
    //查询工位
    function getcameraByid(id){
      state.show=true
      getCamera(id).then(res=>{
        camera.value=res.data
        var led_status=res.data.led_status
        //1 是通电，0是断电状态
        if (led_status==1){
          state.openstatus=true
        }
        else{
          state.openstatus=false
        }
        state.show=false
        dictmethod()
      })
    }
    function cameraupdate(){
      state.show=true
      updateCamera(camera.value).then(res=>{
        state.show=false
        if (res.code==200){
          showToast.success("更新成功！")
        }
      })
    }
    //开电,断电操作
    function openLed() {
      var openform={"id":camera.id}
      state.show=true
      //如果通电，则调用关电接口
      if (state.openstatus){
        cameraOpenLed(openform).then(response => {
          setTimeout(function (){
            getcameraByid(camera.id)
          },2000)

        });
      }
      else{
        cameraCloseLed(openform).then(response => {
          setTimeout(function (){
            getcameraByid(camera.id)
          },2000)
        });
      }

    }

    //开门操作
    function opendoor() {
      var openform={"id":camera.id}
        cameraOpen(openform).then(response => {
          showToast.success("操作成功！")
        });
    }

    //挂载后调用函数获取项目数据
    onMounted(() => {
      let id=router.currentRoute.value.query.id
      camera.id=id
      getcameraByid(id)
    });
    return {
      ...toRefs(state),
      back,rightClick,camera,cameraupdate,openLed,opendoor
    };
  },
  created () {

  }
}
</script>
<style lang="scss">

</style>
