<template>
  <nut-navbar @on-click-back="back" title="充值列表" @on-click-right="rightClick">
    <template #left>
      <div>返回</div>
    </template>
    <template #right>
      <Scan color="#375DE9;"></Scan>
    </template>
  </nut-navbar>
  <div class="bg">
    <nut-searchbar v-model="param.phone" @search="searchorder" background="linear-gradient(to right, #498ff2, #498ff2)" input-background="#fff">
    </nut-searchbar>
    <div style="padding:3px;background-color: #eeeeee;">
      <nut-pagination v-model="param.pageNum" :show-page-size="3" :total-items="totalnum" :items-per-page="param.pageSize" @change="searchorder" force-ellipses />
      <div v-for="(item,index) in orders" :key="item.id" class="order">
        <div>
          <div>
            <nut-row>
              <nut-col :span="18">
                <div>用户手机号：{{item.phone}}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>管理员：{{item.sysUser.userName}}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>所属店铺：{{ item.shop.name }}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>车牌号：{{ item.plateName }}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>订单总价：{{ item.totalPrice }}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>洗车用时：{{ item.washtime }}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>进入时间：{{ item.enterTime }}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>结束时间：{{ item.endTime }}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>订单状态：
                  <nut-tag v-if="item.status==0" type="primary">正在进行</nut-tag>
                  <nut-tag v-if="item.status==1" type="success">已支付</nut-tag>
                  <nut-tag v-if="item.status==2" type="warning">已支付已开票</nut-tag>
                </div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>支付方式：
                  <nut-tag v-if="item.payType==0" type="primary">余额支付</nut-tag>
                  <nut-tag v-if="item.payType==1" type="success">充值并支付（有优惠）</nut-tag>
                  <nut-tag v-if="item.payType==2" type="warning">组合、直接付款支付</nut-tag>
                </div>
              </nut-col>
            </nut-row>
          </div>
        </div>
      </div>
    </div>
  </div>

  <nut-overlay v-model:visible="show" style="display: flex;align-items: center;justify-content:center;">
    <Loading color="white" width="2rem" height="2rem"></Loading>
  </nut-overlay>
</template>

<script>
import {listOrder} from '@/api/order'
import { onMounted, reactive, ref, toRefs } from 'vue'
import { Loading, Scan } from '@nutui/icons-vue'
import '@nutui/icons-vue/dist/style_iconfont.css';
import { useRouter } from 'vue-router'


export default{
  name: "Orders",
  components:{
    Loading,Scan
  },
  setup() {
    const router=useRouter();
    const state = reactive({
      orders: [],
      show: false
    });
    const totalnum=ref(0);
    const param=reactive({
      phone: null,
      pageNum: 1,
      pageSize: 5,
    })
    const back = () => {
      router.back()
    };
    const rightClick=()=>{
      router.push("/feature/content")
    }
    //初始化
    function init(){
      searchorder()
    }
    //查询工位
    function searchorder(){
      state.show=true
      listOrder(param).then(res=>{
        state.orders=res.rows
        totalnum.value=res.total
        state.show=false
      })
    }
    //挂载后调用函数获取项目数据
    onMounted(() => {
      init()
    });
    return {
      ...toRefs(state),
      param,
      searchorder,
      totalnum,
      back,rightClick,
    };
  }
}
</script>
<style lang="scss">
.order{
  background-color:white;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 3px;
  margin-top:5px;
  text-align: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
