import axios from 'axios'
// import { Notify, Toast,Dialog } from '@nutui/nutui'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import store from '@/store'
import { showToast } from '@nutui/nutui';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const urlMap = {
  development: 'http://xingwuwjn.nat300.top/backapi',
  production: 'https://www.lightwashcar.top/backapi'
}
const baseUrl = urlMap[process.env.NODE_ENV]

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: baseUrl,
  // 超时
  timeout: 4000000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers.Authorization = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?'
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName]
      var part = encodeURIComponent(propName) + '='
      if (value !== null && typeof (value) !== 'undefined') {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            if (value[key] !== null && typeof (value[key]) !== 'undefined') {
              const params = propName + '[' + key + ']'
              const subPart = encodeURIComponent(params) + '='
              url += subPart + encodeURIComponent(value[key]) + '&'
            }
          }
        } else {
          url += part + encodeURIComponent(value) + '&'
        }
      }
    }
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode.default
  if (code === 401) {
    // location.href = '/login';
    store.dispatch('LogOut').then(() => {
      location.href = '/login';
    })
  } else if (code === 500) {
    showToast.fail(msg);
    return Promise.reject(new Error(msg))
  } else if (code !== 200) {
    showToast.fail(msg);
    return Promise.reject('error')
  } else {
    return res.data
  }
},
error => {
  console.log('err' + error)
  let { message } = error
  if (message === 'Network Error') {
    message = '后端接口连接异常'
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时'
  } else if (message.includes('Request failed with status code')) {
    message = '系统接口' + message.substr(message.length - 3) + '异常'
  }
  showToast.fail(message);
  return Promise.reject(error)
}
)

export default service
