import request from '@/utils/request'


// 查询店铺列表
export function statisticsdata(query) {
  return request({
    url: '/business/statistics/phonealldata',
    method: 'get',
    params: query
  })
}
