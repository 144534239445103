<template>
  <div style="height: 100%">
    <nut-tabbar v-model="tabindex" @tab-switch="tabSwitch" bottom safe-area-inset-bottom >
      <nut-tabbar-item v-for="(item,index) in List" :tab-title="item.title"
                       @click="switchTab(index, item.pagePath)">
        <template #icon="props">
          <img :src="props.active ? item.active : item.unactive" alt="" />
        </template>
      </nut-tabbar-item>
    </nut-tabbar>
    <router-view></router-view>
  </div>

</template>
<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { computed }  from 'vue'
import tongji_on from "@/assets/images/tongji_on.png";
import tongji from "@/assets/images/tongji.png";
import feature_on from "@/assets/images/feature_on.png";
import feature from "@/assets/images/feature.png";
import my from "@/assets/images/my.png";
import my_on from "@/assets/images/my_on.png";


export default {
  name: "LightTabbar",
  setup() {
    const tabindex = computed(() => store.getters.selected)
    const store = useStore()
    const router=useRouter();
    // const tabindex= ref(0);
    function tabSwitch(item, index) {
      console.log(item);
    }

    const List = [
      {
        title: '统计',
        name: 'home',
        active: tongji_on,
        unactive: tongji,
        pagePath: '/index/content',
      },
      {
        title: '功能',
        name: 'category',
        active: feature_on,
        unactive: feature,
        pagePath: '/feature/content',
      },
      {
        title: '我的',
        name: 'find',
        active: my_on,
        unactive: my,
        pagePath: '/my/content',
      }
    ];
    function switchTab(index, url) {
      setSelected(index)
      router.push({ path: url }).catch(()=>{});
      // Taro.switchTab({ url })
    }

    function setSelected (index) {
      store.dispatch('setSelected', index)
    }
    return {
      tabSwitch,
      switchTab,
      List,
      setSelected,
      tabindex
    };
  }
};
</script>
