import request from '@/utils/request'

// 查询充值明细列表
export function listTopup(query) {
  return request({
    url: '/business/topup/list',
    method: 'get',
    params: query
  })
}

// 查询充值明细详细
export function getTopup(id) {
  return request({
    url: '/business/topup/' + id,
    method: 'get'
  })
}

// 新增充值明细
export function addTopup(data) {
  return request({
    url: '/business/topup',
    method: 'post',
    data: data
  })
}

// 修改充值明细
export function updateTopup(data) {
  return request({
    url: '/business/topup',
    method: 'put',
    data: data
  })
}

// 删除充值明细
export function delTopup(id) {
  return request({
    url: '/business/topup/' + id,
    method: 'delete'
  })
}
