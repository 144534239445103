<template>
  <nut-navbar @on-click-back="back" title="店铺列表" @on-click-right="rightClick">
    <template #left>
      <div>返回</div>
    </template>
    <template #right>
      <Scan color="#375DE9;"></Scan>
    </template>
  </nut-navbar>
  <div class="bg">
    <nut-searchbar v-model="param.name" @search="searchshop" background="linear-gradient(to right, #498ff2, #498ff2)" input-background="#fff">
    </nut-searchbar>
    <div style="padding:3px;background-color: #eeeeee;">
      <nut-pagination v-model="param.pageNum" :show-page-size="3" :total-items="totalnum" :items-per-page="param.pageSize" @change="searchshop" force-ellipses />
      <div v-for="(item,index) in shops" :key="item.id" style="background-color:white;border-radius: 5px;padding: 5px;margin-bottom: 3px;text-align: left;">
        <nut-row>
          <nut-col :span="7">
            <nut-image style="width: 100%;height: 100px" :src="item.shopImage"></nut-image>
          </nut-col>
          <nut-col :span="17" style="padding-left: 5px;padding-top: 1px;">
            <div>
              <div style="font-size: 15px;">
                <nut-row>
                  <nut-col :span="18">
                    <text>{{item.name}}</text>
                  </nut-col>
                  <nut-col :span="6">
                    <nut-button type="info" size="mini" @click="shopdetail(item.id)">
                      详情
                    </nut-button>
                  </nut-col>
                </nut-row>
              </div>
              <nut-row>
                <nut-col :span="20">
                  <nut-tag type="primary" style="margin-left:2px">工位：{{item.stationNum}}</nut-tag>
<!--                  <nut-tag type="danger" style="margin-left:2px;">正在洗车：{{item.washcarnum}}</nut-tag>-->
                </nut-col>
              </nut-row>
              <nut-row>
                <nut-col :span="20">
                  <div style="font-size: 12px;">地址:{{ item.address }}</div>
                </nut-col>
              </nut-row>
              <nut-row>
                <nut-col :span="20">
                  <div style="font-size: 12px;">价格：{{ item.startPrice }}元/{{item.startTime}}分钟 超出{{item.ratePrice}}元/分钟</div>
                </nut-col>
              </nut-row>
            </div>
          </nut-col>
        </nut-row>
      </div>
    </div>
  </div>

  <nut-overlay v-model:visible="show" style="display: flex;align-items: center;justify-content:center;">
    <Loading color="white" width="2rem" height="2rem"></Loading>
  </nut-overlay>
</template>

<script>
import {listShop} from '@/api/shop'
import { onMounted, reactive, ref, toRefs } from 'vue'
import router from '../../router'
import { Loading, Scan } from '@nutui/icons-vue'
import '@nutui/icons-vue/dist/style_iconfont.css';
import { useRouter } from 'vue-router'
export default{
  name: "Shops",
  components:{
    Loading,Scan
  },
  setup() {
    const router=useRouter();
    const state = reactive({
      shops: [],
      show: false
    });
    const totalnum=ref(0);
    const param=reactive({
      name: "",
      pageNum: 1,
      pageSize: 5,
    })
    const back = () => {
     router.back()
    };
    const rightClick=()=>{
      router.push("/feature/content")
    }
    //初始化
    function init(){
      searchshop()
    }
    function shopdetail(id){
      router.push({ path: '/shopdetail',query:{id:id} }).catch(()=>{});
    }
    //查询店铺
    function searchshop(){
      state.show=true
      listShop(param).then(res=>{
        state.shops=res.rows
        totalnum.value=res.total
        state.show=false
      })
    }
    //挂载后调用函数获取项目数据
    onMounted(() => {
      init()
    });
    return {
      ...toRefs(state),
      param,
      searchshop,
      totalnum,
      back,rightClick,
      shopdetail
    };
  }
}
</script>
<style lang="scss">

</style>
