import router from './router'
import store from './store'
import { Notify } from '@nutui/nutui';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'


NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/wechat/weixinauth']

//tab页面
const tablist=['/index/content','/feature/content','/my/content']

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (tablist.indexOf(to.path) !== -1){
    if (tablist.indexOf(to.path)==0){
      store.dispatch('setSelected', 0)
    }
    if (tablist.indexOf(to.path)==1){
      store.dispatch('setSelected', 1)
    }
    if (tablist.indexOf(to.path)==2){
      store.dispatch('setSelected', 2)
    }
  }
  if (getToken()) {
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/index/content' })
      NProgress.done()
    } else {
      next()
    }
  } else {
    // 没有token在免登录白名单，直接进入
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
