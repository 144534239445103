import { createStore } from 'vuex'
import getters from './getters'
import user from './modules/user'


export default createStore({
  state: {
    selected: 0,
    signimage: "",
    company: null,
    signpdf: "",
    currentcompany: null,
    userId: null
  },
  mutations: {
    SET_SELECTED (state, payload) {
      state.selected = payload
    },
    //缓存签名数据
    SET_SIGNIMAGE: (state, signimage) => {
      state.signimage = signimage
    },
    //缓存企业数据
    SET_COMPANY: (state, company) => {
      state.company = company
    },
    //缓存二维码信息
    SET_USERID: (state, userId) => {
      state.userId = userId
    },
    //缓存合同数据
    SET_SIGNPDF: (state, signpdf) => {
      state.signpdf = signpdf
    },
    SET_CURRENTCOMPANY: (state, currentcompany ) => {
      state.currentcompany = currentcompany
    }
  },
  actions: {
    setSelected (context, index) {
      context.commit('SET_SELECTED', index)
    },
    Setsign({ commit }, signimage) {
      commit('SET_SIGNIMAGE', signimage)
    },
    SetCompany({ commit }, company) {
      commit('SET_COMPANY', company)
    },
    SetUserIdDeptId({ commit }, userId) {
      commit('SET_USERID', userId)
    },
    SetSignPdf({ commit }, signpdf) {
      commit('SET_SIGNPDF', signpdf)
    },
    SETCURRENTCOMPANY({ commit }, currentcompany) {
      commit('SET_CURRENTCOMPANY', currentcompany)
    }
  },
  modules: {
    user
  },
  getters
})
