<template>
  <div class="tab-bar">
    <div class="tab-bar-border"></div>
    <div v-for="(item, index) in list" :key="index" class="tab-bar-item" @click="switchTab(index, item.pagePath)">
      <nut-image :src="selected === index ? item.selectedIconPath : item.iconPath" />
      <div :style="{ color: selected === index ? selectedColor : color }">{{item.text}}</div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, toRefs } from 'vue'
import { useRouter } from 'vue-router';
import { computed }  from 'vue'
import { useStore } from 'vuex'

export default {
  name: "SystemDh",
  setup(){

    const store = useStore()
    const router=useRouter();
    const selected = computed(() => store.getters.selected)
    const color = '#000000'
    const selectedColor = '#DC143C'
    const list = [
      {
        pagePath: '/',
        selectedIconPath: '../images/tabbar_home_on.png',
        iconPath: '../images/tabbar_home.png',
        text: '首页'
      },
      {
        pagePath: '/feature',
        selectedIconPath: '../images/tabbar_cate_on.png',
        iconPath: '../images/tabbar_cate.png',
        text: '功能'
      },
      {
        pagePath: '/my',
        selectedIconPath: '../images/tabbar_my_on.png',
        iconPath: '../images/tabbar_my.png',
        text: '个人'
      }
    ]

    function switchTab(index, url) {
      setSelected(index)
      router.push({ path: url }).catch(()=>{});
      // Taro.switchTab({ url })
    }

    function setSelected (index) {
      store.dispatch('setSelected', index)
    }
    return {
      list,
      switchTab,
      setSelected
    };
  },
  methods:{
    navigation(item) {
      this.$router.push({path:item.item.path})
    }

  }
}
</script>
<style>
.tab-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: white;
  display: flex;
  padding-bottom: env(safe-area-inset-bottom);
}

.tab-bar-border {
  background-color: rgba(0, 0, 0, 0.33);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  transform: scaleY(0.5);
}

.tab-bar-item {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tab-bar-item cover-image {
  width: 54px;
  height: 54px;
}

.tab-bar-item cover-view {
  font-size: 20px;
}
</style>
