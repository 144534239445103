<template>
    <div class="mycontent">
      <div class="mytitle">
        <div style="display: flex;flex-direction: row;padding: 14px;align-items: center;">
          <div style="padding-left: 10px">
            <nut-avatar size="large">
              <img src="https://img12.360buyimg.com/imagetools/jfs/t1/196430/38/8105/14329/60c806a4Ed506298a/e6de9fb7b8490f38.png" />
            </nut-avatar>
          </div>
          <div style="margin-left: 15px;font-size: 22px;color: #eeeeee;" >
            <div>{{username}}</div>
          </div>
        </div>
        <div>
          <nut-row style="color: white;text-align: center;font-size: 16px;">
            <nut-col :span="8">
              <div>{{shopsnum}}</div>
              <div>店铺数</div>
            </nut-col>
            <nut-col :span="8">
              <div>{{cameras}}</div>
              <div>工位数</div>
            </nut-col>
            <nut-col :span="8">
              <div>{{wusernum}}</div>
              <div>会员数</div>
            </nut-col>
          </nut-row>
        </div>
      </div>
      <div class="ordercard">
        <div style="padding-top: 20px;">
          <nut-row style="color: white;text-align: center;">
            <nut-col :span="12">
              <nut-button type="info" @click="personal()" color="linear-gradient(to right, #498ff2, #3366ff)">
                个人信息
                <template #icon>
                  <IconFont font-class-name="iconfont" class-prefix="icon" name="gerenxinxi" size="25px"></IconFont>
                </template>
              </nut-button>
            </nut-col>
            <nut-col :span="12">
              <nut-button type="info" @click="qy()" color="linear-gradient(to right, #498ff2, #3366ff)">
                系统续费
                <template #icon>
                  <IconFont font-class-name="iconfont" class-prefix="icon" name="xufei" size="25px"></IconFont>
                </template>
              </nut-button>
            </nut-col>
          </nut-row>
        </div>
        <div style="margin-top: 15px;">
          <nut-grid :column-num="3" :border="false">
            <nut-grid-item text="店铺管理" to="/shops">
              <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="dianpu1" color="#0061b0" size="30px"></IconFont>
            </nut-grid-item>
            <nut-grid-item text="会员查询" to="/remains">
              <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="haoyou" color="#a686ba" size="30px"></IconFont>
            </nut-grid-item>
            <nut-grid-item text="工位管理" to="/cameras">
              <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="Garage" color="#1aaba8" size="30px"></IconFont>
            </nut-grid-item>
          </nut-grid>
        </div>
      </div>
    </div>
</template>

<script>
import { IconFont } from '@nutui/icons-vue';
import '@nutui/icons-vue/dist/style_iconfont.css';
import { onMounted, reactive, ref, toRefs } from 'vue'
import { Toast } from '@nutui/nutui'
import SystemDh from '../../components/SystemDh'
import {getUserphoneProfile} from '@/api/user';
import { useRouter } from 'vue-router'


export default {
  name: "My",
  components: {
    IconFont,
    SystemDh
  },
  setup() {
    const show = ref(false);
    const router=useRouter();
    const data = reactive({
      username: '',
      shopsnum: 0,
      wusernum: 0,
      cameras: 0,
      closeoverlay: false,
      desc: '',
      columns: [],
      currentCompany: [],

    })
    const confirm = ( { selectedValue,selectedOptions })=>{

      data.desc = selectedValue.join(',');
      data.currentCompany=selectedOptions[0]
    }
    const themeVars = reactive({
      NutGridItemContentPadding: '5px 3px',
      NutGridItemTextFontSize: '20'
    });
    function tabSwitch(item, index) {
      console.log(item, index);
    }
    function personal(){
      router.push({ path: '/personal' }).catch(()=>{});
    }
    function init(){
      //获取个人用户余额
      getUserphoneProfile().then((response)=>{
        if (response.code==200){
          data.username=response.data.userName
          data.cameras=response.cameranum
          data.wusernum=response.wusernum
          data.shopsnum=response.shopnum
        }
      })
    }
    //挂载后调用函数获取项目数据
    onMounted(() => {
      init()
    });
    return {
      tabSwitch,
      ...toRefs(data),
      show,
      personal

    };

  },
  methods: {

  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
#order{
  background: #498ff2;
  color: #fff;
  font-size: 16px;
  text-align: left;
}
.mycontent{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mytitle{
  background-color: #498ff2;
  width:100%;
  font-size: 14px;
  padding-bottom: 60px;
  min-height: 140px;
}
.ordercard{
  width:90%;
  vertical-align: middle;
  background-color:white;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  margin-top: -60px;
  flex-grow:0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.ordercard1{
  display: flex;
  flex-direction: row;
  width:90%;
  height: 100%;
  background-color:white;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  margin-top:40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size:28px;
}
.jsdata{
  display: flex;
  flex-direction: row;
  padding: 14px;
  align-items: center;
  justify-content:center;
  width:50%
}
</style>
