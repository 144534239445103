<template>
  <nut-navbar @on-click-back="back" title="提现记录" @on-click-right="rightClick">
    <template #left>
      <div>返回</div>
    </template>
    <template #right>
      <Scan color="#375DE9;"></Scan>
    </template>
  </nut-navbar>
  <div class="bg">
    <nut-searchbar v-model="param.phone" @search="searchwithdraws" background="linear-gradient(to right, #498ff2, #498ff2)" input-background="#fff">
    </nut-searchbar>
    <div style="padding:3px;background-color: #eeeeee;">
      <nut-pagination v-model="param.pageNum" :show-page-size="3" :total-items="totalnum" :items-per-page="param.pageSize" @change="searchwithdraws" force-ellipses />
      <div v-for="(item,index) in withdraws" :key="item.id" class="withdraw">
        <div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>提现人：{{item.sysUser.userName}}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>提现金额：{{item.price}}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>提现时间：{{item.creteTime}}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>审核时间：{{item.shTime}}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <div>
              提现类型 :
              <nut-tag v-if="item.withdrawtype==0" type="primary">银行卡</nut-tag>
              <nut-tag v-if="item.withdrawtype==1" type="danger">微信</nut-tag>
            </div>
          </div>
          <div>
            <div>
              提现状态 :
              <nut-tag v-if="item.status==0" type="primary">待审核</nut-tag>
              <nut-tag v-if="item.status==1" type="danger">已审核提现</nut-tag>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <nut-overlay v-model:visible="show" style="display: flex;align-items: center;justify-content:center;">
    <Loading color="white" width="2rem" height="2rem"></Loading>
  </nut-overlay>
</template>

<script>
import {listWithdraw} from '@/api/withdraw'
import { onMounted, reactive, ref, toRefs } from 'vue'
import { Loading, Scan } from '@nutui/icons-vue'
import '@nutui/icons-vue/dist/style_iconfont.css';
import { useRouter } from 'vue-router'
export default{
  name: "Withdraws",
  components:{
    Loading,Scan
  },
  setup() {
    const router=useRouter();
    const state = reactive({
      withdraws: [],
      show: false
    });
    const totalnum=ref(0);
    const param=reactive({
      phone: null,
      pageNum: 1,
      pageSize: 5,
    })
    const back = () => {
      router.back()
    };
    const rightClick=()=>{
      router.push("/my/content")
    }
    //初始化
    function init(){
      searchwithdraws()
    }
    //查询工位
    function searchwithdraws(){
      state.show=true
      listWithdraw(param).then(res=>{
        state.withdraws=res.rows
        totalnum.value=res.total
        state.show=false
      })
    }
    //挂载后调用函数获取项目数据
    onMounted(() => {
      init()
    });
    return {
      ...toRefs(state),
      param,
      searchwithdraws,
      totalnum,
      back,rightClick,
    };
  }
}
</script>
<style lang="scss">
.withdraw{
  background-color:white;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 3px;
  margin-top:5px;
  text-align: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
