import request from '@/utils/request'


// 提现
export function tixian(data) {
  return request({
    url: '/business/withdraw/tixian',
    method: 'post',
    data: data
  })
}

// 查询提现记录表列表
export function listWithdraw(query) {
  return request({
    url: '/business/withdraw/list',
    method: 'get',
    params: query
  })
}

// 查询提现记录表详细
export function getWithdraw(id) {
  return request({
    url: '/business/withdraw/' + id,
    method: 'get'
  })
}

// 新增提现记录表
export function addWithdraw(data) {
  return request({
    url: '/business/withdraw',
    method: 'post',
    data: data
  })
}

// 修改提现记录表
export function updateWithdraw(data) {
  return request({
    url: '/business/withdraw',
    method: 'put',
    data: data
  })
}

// 删除提现记录表
export function delWithdraw(id) {
  return request({
    url: '/business/withdraw/' + id,
    method: 'delete'
  })
}

