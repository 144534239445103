import request from '@/utils/request'

// 查询工位，一个摄像头对应一个工位列表
export function listCamera(query) {
  return request({
    url: '/business/camera/list',
    method: 'get',
    params: query
  })
}

// 查询工位，一个摄像头对应一个工位详细
export function getCamera(id) {
  return request({
    url: '/business/camera/' + id,
    method: 'get'
  })
}

// 新增工位，一个摄像头对应一个工位
export function addCamera(data) {
  return request({
    url: '/business/camera',
    method: 'post',
    data: data
  })
}

// 修改工位，一个摄像头对应一个工位
export function updateCamera(data) {
  return request({
    url: '/business/camera',
    method: 'put',
    data: data
  })
}


// 修改工位，一个摄像头对应一个工位
export function updatejmCamera(data) {
  return request({
    url: '/business/camera/jmedit',
    method: 'post',
    data: data
  })
}

// 开门操作
export function cameraOpen(data) {
  return request({
    url: '/business/camera/cameraOpen',
    method: 'post',
    data: data
  })
}



//通电操作
export function cameraOpenLed(data) {
  return request({
    url: '/business/camera/cameraOpenLed',
    method: 'post',
    data: data
  })
}

//通电操作
export function cameraCloseLed(data) {
  return request({
    url: '/business/camera/cameraCloseLed',
    method: 'post',
    data: data
  })
}




// 删除工位，一个摄像头对应一个工位
export function delCamera(id) {
  return request({
    url: '/business/camera/' + id,
    method: 'delete'
  })
}
