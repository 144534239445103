import request from '@/utils/request'

//异步访问微信置换code接口
export function getWxCode(appid,redirectUrl) {
  return request({
    url: '/connect/oauth2/authorize?appid='+appid+'&redirect_uri='+redirectUrl+'&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect',
    method: 'get',
    baseURL: 'https://open.weixin.qq.com'
  })
}

// 获取code请求的url
export function wechatAuthorize(data) {
  return request({
    url: '/wechat/authorize/',
    method: 'post',
    data: data
  })
}

