<template>
  <nut-navbar @on-click-back="back" title="工位列表" @on-click-right="rightClick">
    <template #left>
      <div>返回</div>
    </template>
    <template #right>
      <Scan color="#375DE9;"></Scan>
    </template>
  </nut-navbar>
  <div class="bg">
    <nut-searchbar v-model="param.name" @search="searchmera" background="linear-gradient(to right, #498ff2, #498ff2)" input-background="#fff">
    </nut-searchbar>
    <div style="padding:3px;background-color: #eeeeee;">
      <nut-pagination v-model="param.pageNum" :show-page-size="3" :total-items="totalnum" :items-per-page="param.pageSize" @change="searchmera" force-ellipses />
      <div v-for="(item,index) in cameras" :key="item.id" class="camera">
            <div>
              <div>
                <nut-row>
                  <nut-col :span="18">
                    <div>工位名称：{{item.deviceName}}</div>
                  </nut-col>
                  <nut-col :span="6">
                    <nut-button type="info" size="mini" @click="cameradetail(item.id)">
                      详情
                    </nut-button>
                  </nut-col>
                </nut-row>
              </div>
              <div>
                <nut-row>
                  <nut-col :span="24">
                    <div>店铺名称：{{item.shop.name}}</div>
                  </nut-col>
                </nut-row>
              </div>
              <div>
                <nut-row>
                  <nut-col :span="24">
                    <div>序列号：{{ item.serialno }}</div>
                  </nut-col>
                </nut-row>
              </div>
              <div>
                <nut-row>
                  <nut-col :span="24">
                    <div>工位有效期至：<text style="color: #ff0000">{{ item.rent_time }}</text></div>
                  </nut-col>
                </nut-row>
              </div>
              <div>
                <nut-row>
                  <nut-col :span="24">
                    <div>
                      工位状态 :
                      <nut-tag v-if="item.status==0" type="primary">空闲</nut-tag>
                      <nut-tag v-if="item.status==2" type="warning">维修中</nut-tag>
                      <nut-tag v-if="item.status==1" type="danger">忙碌</nut-tag>
                    </div>
                  </nut-col>
                </nut-row>
              </div>
            </div>
      </div>
    </div>
  </div>

  <nut-overlay v-model:visible="show" style="display: flex;align-items: center;justify-content:center;">
    <Loading color="white" width="2rem" height="2rem"></Loading>
  </nut-overlay>
</template>

<script>
import {listCamera} from '@/api/camera'
import { onMounted, reactive, ref, toRefs } from 'vue'
import { Loading, Scan } from '@nutui/icons-vue'
import '@nutui/icons-vue/dist/style_iconfont.css';
import { useRouter } from 'vue-router'
export default{
  name: "Cameras",
  components:{
    Loading,Scan
  },
  setup() {
    const router=useRouter();
    const state = reactive({
      cameras: [],
      show: false
    });
    const totalnum=ref(0);
    const param=reactive({
      name: "",
      pageNum: 1,
      pageSize: 5,
    })
    const back = () => {
      router.back()
    };
    const rightClick=()=>{
      router.push("/feature/content")
    }
    //初始化
    function init(){
      searchmera()
    }
    function cameradetail(id){
      router.push({ path: '/cameradetail',query:{id:id} }).catch(()=>{});
    }
    //查询工位
    function searchmera(){
      state.show=true
      listCamera(param).then(res=>{
        state.cameras=res.rows
        totalnum.value=res.total
        state.show=false
      })
    }
    //挂载后调用函数获取项目数据
    onMounted(() => {
      init()
    });
    return {
      ...toRefs(state),
      param,
      searchmera,
      totalnum,
      back,rightClick,
      cameradetail
    };
  }
}
</script>
<style lang="scss">
.camera{
  background-color:white;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 3px;
  margin-top:5px;
  text-align: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
