import { login, logout, getInfo } from '../../api/login'
import { getToken, setToken, removeToken } from '../../utils/auth'

  const state= {
    token: getToken(),
    name: '',
    avatar: '',
    // roles: [],
    // permissions: []
  }

  const mutations= {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    // SET_ROLES: (state, roles) => {
    //   state.roles = roles
    // },
    // SET_PERMISSIONS: (state, permissions) => {
    //   state.permissions = permissions
    // }
  }

  const actions= {

    SetToken({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    // 登录
    Login({ commit }, userInfo) {
      console.log("store设置登录信息")
      const username = userInfo.username.trim()
      const password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        login(username, password, code, uuid).then(res => {
          setToken(res.token)
          commit('SET_TOKEN', res.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }

export default {
  state,
  actions,
  mutations
}
// export default user
