<template>
  <div style="margin-top: 5px">
    <nut-cell title="请选择店铺" :desc="popupDesc" @click="show = true"></nut-cell>
    <nut-popup position="bottom" v-model:visible="show">
      <nut-picker v-model="popupValue" :columns="columns" title="请选择店铺" @confirm="popupConfirm" @cancel="show=false">
      </nut-picker>
    </nut-popup>

    <nut-cell title="请选择管理员" :desc="adminpopupDesc" @click="adminshow = true"></nut-cell>
    <nut-popup position="bottom" v-model:visible="adminshow">
      <nut-picker v-model="adminpopupValue" :columns="admincolumns" title="请选择管理员" @confirm="adminpopupConfirm" @cancel="adminshow=false">
      </nut-picker>
    </nut-popup>
    <nut-divider>数据总览</nut-divider>
  <nut-row :gutter="5" style="overflow: hidden;">
    <nut-col :span="8">
        <div class="card-panel">
          <div class="icon-shop card-panel-icon-wrapper">
            <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="dianpu1" color="#0061b0" size="30px"></IconFont>
          </div>
          <div class="staticstext card-panel-description">
            <div class="card-panel-text">店铺总数</div>
            <div>
              <countTo :startVal='0' :endVal='chartdata.shopnum' :duration='3000' class="card-panel-num"/>
            </div>
          </div>
        </div>
    </nut-col>
    <nut-col :span="8">
      <div class="card-panel">
        <div class="icon-shop card-panel-icon-wrapper">
          <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="Garage" color="#1aaba8" size="28px"></IconFont>
        </div>
        <div class="staticstext card-panel-description">
          <div class="card-panel-text">工位总数</div>
          <countTo :startVal='0' :endVal='chartdata.cameranum' :duration='3000' class="card-panel-num"/>
        </div>
      </div>
    </nut-col>
    <nut-col :span="8">
      <div class="card-panel">
        <div class="icon-shop card-panel-icon-wrapper">
          <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="haoyou" color="#a686ba" size="30px"></IconFont>
        </div>
        <div class="staticstext card-panel-description">
          <div class="card-panel-text">会员总数</div>
          <countTo :startVal='0' :endVal='chartdata.wusernum' :duration='3000' class="card-panel-num"/>
        </div>
      </div>
    </nut-col>
  </nut-row>

  <div style="overflow: hidden;padding-top: 5px;">
      <nut-row :gutter="5">
        <nut-col :span="8">
          <div class="card-panel">
            <div class="icon-shop card-panel-icon-wrapper">
              <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="dingdan" color="#13227a" size="30px"></IconFont>
            </div>
            <div class="staticstext card-panel-description">
              <div class="card-panel-text">订单总数</div>
              <div>
                <countTo :startVal='0' :endVal='chartdata.ordernum' :duration='3000' class="card-panel-num"/>
              </div>
            </div>
          </div>
        </nut-col>
        <nut-col :span="8">
          <div class="card-panel">
            <div class="icon-shop card-panel-icon-wrapper">
              <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="renminbi" color="#13227a" size="25px"></IconFont>
            </div>
            <div class="staticstext card-panel-description">
              <div class="card-panel-text">订单金额</div>
              <div>
                <countTo :startVal='0' :endVal='chartdata.price' :decimals="2" :duration='3000' class="card-panel-num"/>
              </div>
            </div>
          </div>
        </nut-col>
        <nut-col :span="8">
          <div class="card-panel">
            <div class="icon-shop card-panel-icon-wrapper">
              <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="chongzhi01" color="#ff8881" size="28px"></IconFont>
            </div>
            <div class="staticstext card-panel-description">
              <div class="card-panel-text">充值金额</div>
              <countTo :startVal='0' :endVal='chartdata.payprice' :decimals="2" :duration='3000' class="card-panel-num"/>
            </div>
          </div>
        </nut-col>

      </nut-row>
    </div>

  <div style="overflow: hidden;padding-top: 5px;">
      <nut-row :gutter="15">
        <nut-col :span="8">
          <div class="card-panel">
            <div class="icon-shop card-panel-icon-wrapper">
              <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="renminbi" color="#ff8881" size="30px"></IconFont>
            </div>
            <div class="staticstext card-panel-description">
              <div class="card-panel-text">到账金额</div>
              <countTo :startVal='0' :endVal='chartdata.payrealprice' :decimals="2" :duration='3000' class="card-panel-num"/>
            </div>
          </div>
        </nut-col>
      </nut-row>
    </div>
    <div>
      <nut-tabs v-model="queryParams.tab1value">
        <nut-tab-pane title="今日">
          <div>
            <div>
              <nut-row :gutter="5" class="staticsdata">
                <nut-col :span="8">
                  <div class="card-panel">
                    <div class="staticstext card-panel-description">
                      <div class="card-panel-text">订单数</div>
                      <countTo :startVal='0' :endVal='chartdata.todayordernum' :duration='3000' class="card-panel-num"/>
                    </div>
                  </div>
                </nut-col>
                <nut-col :span="8">
                  <div class="card-panel">
                    <div class="staticstext card-panel-description">
                      <div class="card-panel-text">订单金额</div>
                      <countTo :startVal='0' :endVal='chartdata.todayprice' :duration='3000' class="card-panel-num" :decimals="2"/>
                    </div>
                  </div>
                </nut-col>
                <nut-col :span="8">
                  <div class="card-panel">
                    <div class="staticstext card-panel-description">
                      <div class="card-panel-text">充值金额</div>
                      <countTo :startVal='0' :endVal='chartdata.todaypayprice' :duration='3000' class="card-panel-num" :decimals="2"/>
                    </div>
                  </div>
                </nut-col>
              </nut-row>
            </div>
            <div style="margin-top: 5px">
              <nut-row :gutter="5">
                <nut-col :span="8">
                  <div class="card-panel">
                    <div class="staticstext card-panel-description">
                      <div class="card-panel-text">充值到账金额</div>
                      <countTo :startVal='0' :endVal='chartdata.todaypayrealprice' :duration='3000' class="card-panel-num" :decimals="2"/>
                    </div>
                  </div>
                </nut-col>
                <nut-col :span="8">
                  <div class="card-panel">
                    <div class="staticstext card-panel-description">
                      <div class="card-panel-text">新增会员</div>
                      <countTo :startVal='0' :endVal='chartdata.todaynwusernum' :duration='3000' class="card-panel-num"/>
                    </div>
                  </div>
                </nut-col>
              </nut-row>
            </div>
          </div>
        </nut-tab-pane>
        <nut-tab-pane title="近7天">
          <div>
          <div>
            <nut-row :gutter="5" class="staticsdata">
              <nut-col :span="8">
                <div class="card-panel">
                  <div class="staticstext card-panel-description">
                    <div class="card-panel-text">订单数</div>
                    <countTo :startVal='0' :endVal='chartdata.ordernum7' :duration='3000' class="card-panel-num"/>
                  </div>
                </div>
              </nut-col>
              <nut-col :span="8">
                <div class="card-panel">
                  <div class="staticstext card-panel-description">
                    <div class="card-panel-text">订单金额</div>
                    <countTo :startVal='0' :endVal='chartdata.price7' :duration='3000' class="card-panel-num" :decimals="2"/>
                  </div>
                </div>
              </nut-col>
              <nut-col :span="8">
                <div class="card-panel">
                  <div class="staticstext card-panel-description">
                    <div class="card-panel-text">充值金额</div>
                    <countTo :startVal='0' :endVal='chartdata.payprice7' :duration='3000' class="card-panel-num" :decimals="2"/>
                  </div>
                </div>
              </nut-col>
            </nut-row>
          </div>
          <div style="margin-top: 5px">
            <nut-row :gutter="5">
              <nut-col :span="8">
                <div class="card-panel">
                  <div class="staticstext card-panel-description">
                    <div class="card-panel-text">充值到账金额</div>
                    <countTo :startVal='0' :endVal='chartdata.payrealprice7' :duration='3000' class="card-panel-num" :decimals="2"/>
                  </div>
                </div>
              </nut-col>
              <nut-col :span="8">
                <div class="card-panel">
                  <div class="staticstext card-panel-description">
                    <div class="card-panel-text">新增会员</div>
                    <countTo :startVal='0' :endVal='chartdata.nwusernum7' :duration='3000' class="card-panel-num"/>
                  </div>
                </div>
              </nut-col>
            </nut-row>
          </div>
        </div>
        </nut-tab-pane>
        <nut-tab-pane title="近30天">
          <div>
          <div>
            <nut-row :gutter="5" class="staticsdata">
              <nut-col :span="8">
                <div class="card-panel">
                  <div class="staticstext card-panel-description">
                    <div class="card-panel-text">订单数</div>
                    <countTo :startVal='0' :endVal='chartdata.ordernum30' :duration='3000' class="card-panel-num"/>
                  </div>
                </div>
              </nut-col>
              <nut-col :span="8">
                <div class="card-panel">
                  <div class="staticstext card-panel-description">
                    <div class="card-panel-text">订单金额</div>
                    <countTo :startVal='0' :endVal='chartdata.price30' :duration='3000' class="card-panel-num" :decimals="2"/>
                  </div>
                </div>
              </nut-col>
              <nut-col :span="8">
                <div class="card-panel">
                  <div class="staticstext card-panel-description">
                    <div class="card-panel-text">充值金额</div>
                    <countTo :startVal='0' :endVal='chartdata.payprice30' :duration='3000' class="card-panel-num" :decimals="2"/>
                  </div>
                </div>
              </nut-col>
            </nut-row>
          </div>
          <div style="margin-top: 5px">
            <nut-row :gutter="5">
              <nut-col :span="8">
                <div class="card-panel">
                  <div class="staticstext card-panel-description">
                    <div class="card-panel-text">充值到账金额</div>
                    <countTo :startVal='0' :endVal='chartdata.payrealprice30' :duration='3000' class="card-panel-num" :decimals="2"/>
                  </div>
                </div>
              </nut-col>
              <nut-col :span="8">
                <div class="card-panel">
                  <div class="staticstext card-panel-description">
                    <div class="card-panel-text">新增会员</div>
                    <countTo :startVal='0' :endVal='chartdata.nwusernum30' :duration='3000' class="card-panel-num"/>
                  </div>
                </div>
              </nut-col>
            </nut-row>
          </div>
        </div>
        </nut-tab-pane>
      </nut-tabs>
    </div>
  </div>
  <nut-overlay v-model:visible="loadingshow" style="display: flex;align-items: center;justify-content:center;">
    <Loading color="white" width="2rem" height="2rem"></Loading>
  </nut-overlay>
</template>

<script>
import { IconFont,Loading } from '@nutui/icons-vue';
import '@nutui/icons-vue/dist/style_iconfont.css';
import { onMounted, reactive, ref, toRefs } from 'vue'
import { Toast } from '@nutui/nutui'
import { CountTo } from 'vue3-count-to'
import { statisticsdata } from "@/api/statistics"
import {listShop } from '@/api/shop'
import { listUser } from "@/api/user";
import {addDateRange} from "../utils/lightning"

export default {
  name: "Index",
  components: {
    IconFont,
    CountTo
  },
  setup(props) {
      const chartdata=ref({})
      const queryParams= ref({
          userId: null,// 管理用户
          shopId: null,//店铺id
          tab1value: '0'
        })
      const loadingshow=ref(false)
      const show = ref(false);
      const popupDesc = ref();
      const popupValue = ref();
      const columns = ref([
      ]);

      const popupConfirm = ({ selectedValue, selectedOptions }) => {
        popupDesc.value = selectedOptions.map((val) => val.text).join(',');
        show.value = false;
        queryParams.shopId=selectedOptions[0].value
        tongji()
      };

      //管理员
    const adminshow = ref(false);
    const adminpopupDesc = ref();
    const adminpopupValue = ref();
    var admincolumns = ref([
    ]);

    const adminpopupConfirm = ({ selectedValue, selectedOptions }) => {
      adminpopupDesc.value = selectedOptions.map((val) => val.text).join(',');
      adminshow.value = false;
      queryParams.userId=selectedOptions[0].value
      tongji()
    };

    const data = reactive({

    })
    function init(){
      listShop().then(response => {
        var shopList = response.rows;
        shopList.forEach(function(item,index,self){
          var column={}
          column.text=item.name
          column.value=item.id
          columns.value[index]=column
        })
      });
      //查询用户列表
      listUser().then(response => {
        var userList = response.rows;
        userList.forEach(function(item,index,self){
          var admincolumn={}
          admincolumn.text=item.userName
          admincolumn.value=item.userId
          admincolumns.value[index]=admincolumn
        })
      });
      //初始化获取数据
      tongji()
    }
    //统计请求
    function tongji(){
      loadingshow.value=true
      statisticsdata(addDateRange(queryParams)).then(response => {
          chartdata.value=response
          loadingshow.value=false
        }
      );
    }
    //挂载后调用函数获取项目数据
    onMounted(() => {
      init()
    });
    return {
      ...toRefs(data),
      popupValue, show, popupDesc, columns, popupConfirm,
      adminpopupValue, adminshow, adminpopupDesc, admincolumns, adminpopupConfirm,
      queryParams,chartdata,tongji,loadingshow
    };

  },
  methods: {

  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.staticsdata{
    margin-top: 5px;
}
.card-panel {
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  padding: 5px;
  height: 60px;
  cursor: pointer;
  font-size: 10px;
  color: #666;
  background: #fff;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
  border-color: rgba(0, 0, 0, .05);

  .card-panel-icon-wrapper {
    margin: 5px 0 0 5px;
    padding: 3px;
    transition: all 0.38s ease-out;
    border-radius: 6px;
  }

  .card-panel-icon {
    //float: left;
    font-size: 32px;
  }

  .card-panel-description {
    display: flex;
    height: 100%;
    font-weight: bold;
    margin-left: 6px;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    .card-panel-text {
      line-height: 10px;
      font-size: 10px;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .card-panel-num {
      font-size: 14px;
      color: red;
    }
  }
}

</style>
