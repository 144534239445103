<template>
  <nut-navbar @on-click-back="back" title="会员列表" @on-click-right="rightClick">
    <template #left>
      <div>返回</div>
    </template>
    <template #right>
      <Scan color="#375DE9;"></Scan>
    </template>
  </nut-navbar>
  <div class="bg">
    <nut-searchbar v-model="param.phone" @search="searchremain" background="linear-gradient(to right, #498ff2, #498ff2)" input-background="#fff">
    </nut-searchbar>
    <div style="padding:3px;background-color: #eeeeee;">
      <nut-pagination v-model="param.pageNum" :show-page-size="3" :total-items="totalnum" :items-per-page="param.pageSize" @change="searchremain" force-ellipses />
      <div v-for="(item,index) in remains" :key="item.id" class="remain">
        <div>
          <div>
            <nut-row>
              <nut-col :span="18">
                <div>用户手机号：{{item.phone}}</div>
              </nut-col>
<!--              <nut-col :span="6">-->
<!--                <nut-button type="info" size="mini" @click="remaindetail(item.id)">-->
<!--                  详情-->
<!--                </nut-button>-->
<!--              </nut-col>-->
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>管理员：{{item.sysUser.userName}}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>用户余额：{{ item.remain }}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>订单数：{{ item.ordernum }}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>充值总金额：{{ item.topupPrice }}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>到账总金额：{{ item.topupmoney }}</div>
              </nut-col>
            </nut-row>
          </div>
          <div>
            <nut-row>
              <nut-col :span="24">
                <div>最后一次洗车时间：{{ item.washtime }}</div>
              </nut-col>
            </nut-row>
          </div>
        </div>
      </div>
    </div>
  </div>

  <nut-overlay v-model:visible="show" style="display: flex;align-items: center;justify-content:center;">
    <Loading color="white" width="2rem" height="2rem"></Loading>
  </nut-overlay>
</template>

<script>
import {listRemain} from '@/api/remain'
import { onMounted, reactive, ref, toRefs } from 'vue'
import { Loading, Scan } from '@nutui/icons-vue'
import '@nutui/icons-vue/dist/style_iconfont.css';
import { useRouter } from 'vue-router'
export default{
  name: "Remains",
  components:{
    Loading,Scan
  },
  setup() {
    const router=useRouter();
    const state = reactive({
      remains: [],
      show: false
    });
    const totalnum=ref(0);
    const param=reactive({
      phone: null,
      pageNum: 1,
      pageSize: 5,
    })
    const back = () => {
      router.back()
    };
    const rightClick=()=>{
      router.push("/feature/content")
    }
    //初始化
    function init(){
      searchremain()
    }
    function remaindetail(id){
      router.push({ path: '/reamindetail',query:{id:id} }).catch(()=>{});
    }
    //查询工位
    function searchremain(){
      state.show=true
      listRemain(param).then(res=>{
        state.remains=res.rows
        totalnum.value=res.total
        state.show=false
      })
    }
    //挂载后调用函数获取项目数据
    onMounted(() => {
      init()
    });
    return {
      ...toRefs(state),
      param,
      searchremain,
      totalnum,
      back,rightClick,
      remaindetail
    };
  }
}
</script>
<style lang="scss">
.remain{
  background-color:white;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 3px;
  margin-top:5px;
  text-align: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
