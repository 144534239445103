import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";
import "./permission.js"
import NutBig from "@nutui/nutui-bingo";
import "@nutui/nutui-bingo/dist/style.css";
import { IconFont } from '@nutui/icons-vue';
import '@nutui/icons-vue/dist/style_iconfont.css';
import { Grid, GridItem,Tabbar, TabbarItem } from '@nutui/nutui';
import { Menu, MenuItem } from '@nutui/nutui';
import "./assets/font/iconfont.css";
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/lightning";

const app = createApp(App);
app.use(NutUI);
app.use(NutBig);
app.use(IconFont);
app.use(Grid);
app.use(GridItem);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Menu);app.use(MenuItem);
app.use(store).use(router).mount('#app');


