<template>
  <div class="personalbg">
    <div class="personal">
      <div class="logo">
        <nut-avatar size="large">
          <img src="https://img12.360buyimg.com/imagetools/jfs/t1/196430/38/8105/14329/60c806a4Ed506298a/e6de9fb7b8490f38.png" />
        </nut-avatar>
      </div>
      <div class="personalcontent">
        <nut-form>
          <nut-form-item label="昵称">
            <nut-input v-model="personal.userName" type="nickname" :max-length="15" :border="false" class="nut-input-text" placeholder="请输入昵称" readonly/>
          </nut-form-item>
          <nut-form-item label="手机号" class="phoneitem">
            <nut-input placeholder="手机号" :border="false" :readonly="true" v-model="personal.phonenumber" readonly>
            </nut-input>
          </nut-form-item>
          <nut-form-item label="手机号" class="phoneitem">
            <nut-input placeholder="手机号" :border="false" :readonly="true" v-model="personal.email" readonly>
            </nut-input>
          </nut-form-item>
        </nut-form>
      </div>
      <nut-button type="info" style="width:95%;margin-top:10px;" @click="logout()">退出登录</nut-button>
    </div>
  </div>

</template>

<script>
import { useStore } from 'vuex'
import { onMounted, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { IconFont } from '@nutui/icons-vue';
import {getUserProfile} from '@/api/user';
export default {
  name: "Person",
  components: {
    IconFont
  },
  setup () {
    const store = useStore()
    const router=useRouter();
    const data = reactive({
      personal: {
        avatar: "https://img12.360buyimg.com/imagetools/jfs/t1/196430/38/8105/14329/60c806a4Ed506298a/e6de9fb7b8490f38.png"
      },
    });

    //退出
    function logout(){
      store.dispatch('LogOut').then(() => {
        router.push({ path: '/login' }).catch(()=>{});
        })
    }
    //挂载获取地址中参数
    onMounted(()=>{
      //获取个人信息
      getUserProfile().then((response)=>{
        if (response.code==200){
          data.personal=response.data
        }
      })
    })

    return{
      ...toRefs(data),
      logout
    }

  }
}
</script>

<style>

.personalbg{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
}
</style>
