<template>
  <div class="feature">
    <nut-swiper :pagination-visible="true" pagination-color="#426543" auto-play="3000" :height="160">
      <nut-swiper-item>
        <img src="https://www.lightwashcar.top/headimg/npo.jpg" alt="" />
      </nut-swiper-item>
    </nut-swiper>
    <div style="margin-top: 15px;">
      <nut-grid :column-num="3" :gutter="15">
        <nut-grid-item text="店铺管理" @click="shops()">
          <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="dianpu1" color="#0061b0" size="30px"></IconFont>
        </nut-grid-item>
        <nut-grid-item text="工位管理" @click="cameras()">
          <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="Garage" color="#1aaba8" size="28px">
          </IconFont>
        </nut-grid-item>
        <nut-grid-item text="会员管理" @click="remains()">
          <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="haoyou" color="#a686ba" size="30px"></IconFont>
        </nut-grid-item>
        <nut-grid-item text="充值设置" @click="topupsetting()">
          <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="shezhi" color="#0061b0" size="30px"></IconFont>
        </nut-grid-item>
        <nut-grid-item text="充值记录" @click="topups()">
          <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="chongzhi01" color="#ff8881" size="28px"></IconFont>
        </nut-grid-item>
        <nut-grid-item text="评分查看" @click="pinfen()">
          <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="shoucang" color="#a686ba" size="30px"></IconFont>
        </nut-grid-item>
        <nut-grid-item text="抖音核销" @click="douyinhx()">
          <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="douyin1" size="30px"></IconFont>
        </nut-grid-item>
        <nut-grid-item text="订单记录" @click="orders()">
          <IconFont font-class-name="iconfont" class="card-panel-icon" class-prefix="icon" name="dingdan" color="#13227a" size="30px"></IconFont>
        </nut-grid-item>
      </nut-grid>
    </div>

  </div>
</template>


<script>
import { IconFont } from '@nutui/icons-vue'
import { useRouter } from 'vue-router'
import { onMounted, reactive, ref, toRefs } from 'vue'
import { showToast } from '@nutui/nutui';

export default {
  name: "Feature",
  components: {
    IconFont,
  },
  setup() {
    const router=useRouter();
    const state = reactive({
      shops: []

    });
    //调转到shops 页面
    function shops(){
      router.push({ path: '/shops' }).catch(()=>{});
    }
    function cameras(){
      router.push({ path: '/cameras' }).catch(()=>{});
    }
    function remains(){
      router.push({ path: '/remains' }).catch(()=>{});
    }
    function topups(){
      router.push({ path: '/topups' }).catch(()=>{});
    }
    function orders(){
      router.push({ path: '/orders' }).catch(()=>{});
    }
    function pinfen(){
      showToast.text("开发中，敬请期待")
    }
    function topupsetting(){
      showToast.text("开发中，敬请期待,请在pc端设置")
    }
    function douyinhx(){
      showToast.text("开发中，敬请期待")
    }
    //挂载后调用函数获取项目数据
    onMounted(() => {

    });
    return {
      ...toRefs(state),
      shops,cameras,remains,topups,pinfen,topupsetting,douyinhx,orders
    };
}

}
</script>


<style rel="stylesheet/scss" lang="scss">
.feature{
  height: 100%;
  flex-direction: column;
  justify-content:center;

}
.nut-swiper-item {
  line-height: 130px;
}
img {
  width: 100%;
  height: 100%;
}

</style>
