import request from '@/utils/request'

// 查询remain列表
export function listRemain(query) {
  return request({
    url: '/business/remain/list',
    method: 'get',
    params: query
  })
}

// 查询remain详细
export function getRemain(id) {
  return request({
    url: '/business/remain/' + id,
    method: 'get'
  })
}

// 新增remain
export function addRemain(data) {
  return request({
    url: '/business/remain',
    method: 'post',
    data: data
  })
}

// 修改remain
export function updateRemain(data) {
  return request({
    url: '/business/remain',
    method: 'put',
    data: data
  })
}

// 删除remain
export function delRemain(id) {
  return request({
    url: '/business/remain/' + id,
    method: 'delete'
  })
}
